/**
 * @fileoverview gRPC-Web generated client stub for trailer
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/trailer/type.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';
import * as protobuf_trailer_type_pb from '../../protobuf/trailer/type_pb';


export class TrailerTypeServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/trailer.TrailerTypeService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_trailer_type_pb.CreateTrailerTypeRequest,
    protobuf_model_merged_pb.TrailerTypeResponse,
    (request: protobuf_trailer_type_pb.CreateTrailerTypeRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.TrailerTypeResponse.deserializeBinary
  );

  create(
    request: protobuf_trailer_type_pb.CreateTrailerTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.TrailerTypeResponse>;

  create(
    request: protobuf_trailer_type_pb.CreateTrailerTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TrailerTypeResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.TrailerTypeResponse>;

  create(
    request: protobuf_trailer_type_pb.CreateTrailerTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TrailerTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/trailer.TrailerTypeService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/trailer.TrailerTypeService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/trailer.TrailerTypeService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_trailer_type_pb.UpdateTrailerTypeRequest,
    protobuf_model_merged_pb.TrailerTypeResponse,
    (request: protobuf_trailer_type_pb.UpdateTrailerTypeRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.TrailerTypeResponse.deserializeBinary
  );

  update(
    request: protobuf_trailer_type_pb.UpdateTrailerTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.TrailerTypeResponse>;

  update(
    request: protobuf_trailer_type_pb.UpdateTrailerTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TrailerTypeResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.TrailerTypeResponse>;

  update(
    request: protobuf_trailer_type_pb.UpdateTrailerTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TrailerTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/trailer.TrailerTypeService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/trailer.TrailerTypeService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/trailer.TrailerTypeService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_trailer_type_pb.GetTrailerTypesRequest,
    protobuf_trailer_type_pb.GetTrailerTypesResponse,
    (request: protobuf_trailer_type_pb.GetTrailerTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_trailer_type_pb.GetTrailerTypesResponse.deserializeBinary
  );

  get(
    request: protobuf_trailer_type_pb.GetTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_trailer_type_pb.GetTrailerTypesResponse>;

  get(
    request: protobuf_trailer_type_pb.GetTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_trailer_type_pb.GetTrailerTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_trailer_type_pb.GetTrailerTypesResponse>;

  get(
    request: protobuf_trailer_type_pb.GetTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_trailer_type_pb.GetTrailerTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/trailer.TrailerTypeService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/trailer.TrailerTypeService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/trailer.TrailerTypeService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_trailer_type_pb.DeleteTrailerTypesRequest,
    protobuf_trailer_type_pb.DeleteTrailerTypesResponse,
    (request: protobuf_trailer_type_pb.DeleteTrailerTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_trailer_type_pb.DeleteTrailerTypesResponse.deserializeBinary
  );

  delete(
    request: protobuf_trailer_type_pb.DeleteTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_trailer_type_pb.DeleteTrailerTypesResponse>;

  delete(
    request: protobuf_trailer_type_pb.DeleteTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_trailer_type_pb.DeleteTrailerTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_trailer_type_pb.DeleteTrailerTypesResponse>;

  delete(
    request: protobuf_trailer_type_pb.DeleteTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_trailer_type_pb.DeleteTrailerTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/trailer.TrailerTypeService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/trailer.TrailerTypeService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/trailer.TrailerTypeService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_trailer_type_pb.ListTrailerTypesRequest,
    protobuf_trailer_type_pb.ListTrailerTypesResponse,
    (request: protobuf_trailer_type_pb.ListTrailerTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_trailer_type_pb.ListTrailerTypesResponse.deserializeBinary
  );

  list(
    request: protobuf_trailer_type_pb.ListTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_trailer_type_pb.ListTrailerTypesResponse>;

  list(
    request: protobuf_trailer_type_pb.ListTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_trailer_type_pb.ListTrailerTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_trailer_type_pb.ListTrailerTypesResponse>;

  list(
    request: protobuf_trailer_type_pb.ListTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_trailer_type_pb.ListTrailerTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/trailer.TrailerTypeService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/trailer.TrailerTypeService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/trailer.TrailerTypeService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_trailer_type_pb.ExportTrailerTypesRequest,
    protobuf_trailer_type_pb.ExportTrailerTypesResponse,
    (request: protobuf_trailer_type_pb.ExportTrailerTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_trailer_type_pb.ExportTrailerTypesResponse.deserializeBinary
  );

  export(
    request: protobuf_trailer_type_pb.ExportTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_trailer_type_pb.ExportTrailerTypesResponse>;

  export(
    request: protobuf_trailer_type_pb.ExportTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_trailer_type_pb.ExportTrailerTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_trailer_type_pb.ExportTrailerTypesResponse>;

  export(
    request: protobuf_trailer_type_pb.ExportTrailerTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_trailer_type_pb.ExportTrailerTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/trailer.TrailerTypeService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/trailer.TrailerTypeService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

